body {
  margin: 0;
  padding: 0;
  background: #000;
}
.App {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.App #image-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 270px;
  max-width: 270px;
  margin: 0 auto;
  padding: 0;
  justify-content: center;
}
.App #image-list li {
  display: flex;
  background: #000;
  border: 1px solid #fff;
  margin: 0 5px 5px 0;
  padding: 0;
  margin: 0;
  height: 60px;
  width: 60px;
}

.App #image-list li a img {
  max-width: 60px;
  max-height: 60px;
  box-sizing: border-box;
}

@media screen and (min-width: 460px) {
  .App #image-list {
    min-width: 450px;
    max-width: 450px;
  }
}

@media screen and (min-width: 768px) {
  .App #image-list {
    min-width: 759px;
    max-width: 759px;
  }
}

@media screen and (min-width: 992px) {
  .App #image-list {
    min-width: 992px;
    max-width: 992px;
  }
}
